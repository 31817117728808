import React from 'react'

import SarchImg from 'images/top/button_sarch_icon.png'
import { withCustomComponents } from '@arch-log/webapp.atomics/components/Rules'
import { useWindowSize } from 'react-use'
import * as Base from '@arch-log/webapp.shared/KeywordSearch'
import * as styles from './styles.module.scss'
import * as Literals from './Literals'

const CustomKeyword = ({ ...props }) => {
  const { searchPlaceholder, searchShortPlaceholder } = Literals.useLiterals();
  const { width } = useWindowSize();
  return(
  <Base.Form.Keyword
    className={styles.Keyword}
    placeholder={
      (width <= 740)
        ? searchShortPlaceholder()
        : searchPlaceholder()
    }
    {...props}
  />
)}

const CustomSubmit = ({ ...props }) => (
  <Base.Form.Submit className={styles.Submit} {...props}>
    <img src={SarchImg} alt="Sarch" className={styles.Submit_Icon} />
  </Base.Form.Submit>
)

const CustomContainer = ({ children, ...props }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 */
export const Default = withCustomComponents(Base.Default, {
  Form: {
    Inputs: {
      Keyword: CustomKeyword,
    },
    Submit: CustomSubmit,
  },
  Layout: {
    Container: CustomContainer,
  },
})
